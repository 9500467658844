import { IActionContext } from '@msdyn365-commerce/core-internal';

/**
 * Gets Account Number from the action context object
 * @param context
 * @constructor
 */
export const GetAccoutNumberFromContext = (context: IActionContext): number => {
    if (!context.requestContext.user) {
        return 0;
    }
    let accountNum =
        (context.requestContext.query && context.requestContext.query['accountnumber'] && +context.requestContext.query['accountnumber']) ||
        0;
    if (accountNum === 0) {
        accountNum = (context.requestContext.user.customerAccountNumber && +context.requestContext.user.customerAccountNumber) || 0;
    }

    return accountNum;
};
